import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { onMoveAnimation } from 'services/useDevelopUI'
import { useRegister, useLogin } from 'queries/useLogin.query'
import { useCheckConnected } from 'services/useWalletProvider'
import { DataContext } from 'contexts/DataContext'
import './AuthenticateForm.css'
import { useTranslation } from 'react-i18next'

const LoginForm = ({ onTrigger, error, setError, onClose }) => {
	const { t, ready } = useTranslation()
	const [info, setInfo] = React.useState({
		username: '',
		password: '',
	})
	const onDone = () => {
		setError('')
		setInfo({
			username: '',
			password: '',
		})
		onMoveAnimation('authenticate-form', 'moveOutOpacity')
	}
	const onLogin = useLogin(setError, onDone)
	if (ready)
		return (
			<div className='form login-form'>
				<div className='title-container flex flex-row justify-between'>
					<span className='title'>{t('Sign in')}</span>
					<CloseIcon onClick={onClose} />
				</div>
				<div className='input-field'>
					<span>Username</span>
					<label className='filter-search flex relative'>
						<input
							value={info.username}
							onChange={(e) => setInfo({ ...info, username: e.target.value })}
							type='text'
							placeholder={t('Enter your username')}
						/>
					</label>
				</div>
				<div className='input-field'>
					<span>Password</span>
					<label className='filter-search flex relative'>
						<input
							value={info.password}
							onChange={(e) => setInfo({ ...info, password: e.target.value })}
							type='password'
							placeholder={t('Enter your password')}
						/>
					</label>
				</div>
				<span className='link-form'>
					{t(`Don't have an account?`)} <b onClick={() => onTrigger()}>{t(`Sign up`)}</b>
				</span>
				{error ? <span className='form-error'>{error}</span> : null}
				<div onClick={() => onLogin(info)} className='btn-primary'>
					{t('Login')}
				</div>
			</div>
		)
}
const RegisterForm = ({ onTrigger, error, setError, onClose }) => {
	const { t, ready } = useTranslation()
	function onlyLettersAndSpaces(str) {
		return /^[a-z0-9]*$/.test(str)
	}
	const isConnected = useCheckConnected()
	const onDone = () => {
		setError('')
		setInfo({
			username: '',
			password: '',
			confirm: '',
		})
		onMoveAnimation('authenticate-form', 'moveOutOpacity')
	}
	const [info, setInfo] = React.useState({
		username: '',
		password: '',
		confirm: '',
	})
	const onRegister = useRegister(setError, onDone)
	const data = React.useContext(DataContext)
	if (ready)
		return (
			<div className='form login-form'>
				<div className='title-container flex flex-row justify-between'>
					<span className='title'>{t('Sign up')}</span>
					<CloseIcon onClick={onClose} />
				</div>
				{/* <div className='input-field'>
				<span>Email</span>
				<label className='filter-search flex relative mt-2'>
					<input
						autoComplete='off'
						placeholder='Enter your email'
						value={info.email}
						type='email'
						onChange={(e) => {
							setInfo({ ...info, email: e.target.value })
						}}
					/>
				</label>
			</div> */}
				<div className='input-field'>
					<span>{t('Username')}</span>
					<label className='filter-search flex relative mt-2'>
						<input
							autoComplete='false'
							placeholder={t('Enter your username')}
							value={info?.username}
							type='text'
							onChange={(e) => {
								onlyLettersAndSpaces(e.target.value?.toLowerCase())
									? setError('')
									: setError(
											t('Invalid username (should only contain lowercase or numeric letters)')
									  )
								setInfo({ ...info, username: e.target.value?.toLowerCase() })
							}}
						/>
					</label>
				</div>
				<div className='input-field'>
					<span>{t('Password')}</span>
					<label className='filter-search flex relative mt-2'>
						<input
							autoComplete='new-password'
							type='password'
							placeholder={t('Enter your password')}
							defaultValue={info.password}
							onChange={(e) => {
								setInfo({ ...info, password: e.target.value })
							}}
						/>
					</label>
				</div>
				<div className='input-field'>
					<span>{t('Confirm Password')}</span>
					<label className='filter-search flex relative mt-2'>
						<input
							autoComplete='off'
							type='password'
							placeholder={t('Enter your password')}
							value={info.confirm}
							onChange={(e) => {
								setInfo({ ...info, confirm: e.target.value })
							}}
						/>
					</label>
				</div>
				{error ? <span className='form-error'>{error}</span> : null}
				{isConnected ? (
					data.user?.token ? (
						<div
							onClick={() => {
								if (onlyLettersAndSpaces(info?.username)) onRegister(info, data.user?.token)
								else
									setError(t('Invalid username (should only contain lowercase or numeric letters)'))
							}}
							className='btn-primary'
						>
							{t('Sign up')}
						</div>
					) : (
						<div
							onClick={() => {
								if (onlyLettersAndSpaces(info?.username)) onRegister(info, data.user?.token)
								else setError('Invalid username (should only contain lowercase or numeric letters)')
							}}
							className='btn-primary'
						>
							{t('Sign Message')}
						</div>
					)
				) : (
					<div
						onClick={() => onMoveAnimation('connect-modal', 'moveInOpacity')}
						className='btn-primary'
					>
						{t('Connect')}
					</div>
				)}
			</div>
		)
}
export const AuthenticateForm = () => {
	const [activeForm, setActiveForm] = React.useState(1)
	const [error, setError] = React.useState('')
	return (
		<div id='authenticate-form' className='authenticate-form'>
			<div
				onClick={() => {
					onMoveAnimation('authenticate-form', 'moveOutOpacity')
					setError('')
				}}
				className='overlay'
			/>
			{activeForm === 0 ? (
				<LoginForm
					onClose={() => {
						onMoveAnimation('authenticate-form', 'moveOutOpacity')
						setError('')
					}}
					onTrigger={() => setActiveForm(1)}
					error={error}
					setError={setError}
				/>
			) : (
				<RegisterForm
					onClose={() => {
						onMoveAnimation('authenticate-form', 'moveOutOpacity')
						setError('')
					}}
					onTrigger={() => setActiveForm(0)}
					error={error}
					setError={setError}
				/>
			)}
		</div>
	)
}
