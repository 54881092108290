/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { toast } from 'react-toastify'
import { ERROR_CODE } from 'constants/error_code'
import { DataContext } from 'contexts/DataContext'
import { useVerrifyAccount } from './useMyAccount.query'

export const useRegister = (setError, onDone) => {
	const onVerifyKey = useVerrifyAccount()
	return async (info, token) => {
		if (info.password && info.username) {
			if (info?.password === info?.confirm) {
				fetch(`${process.env.REACT_APP_API}/api/v1/player/register_user`, {
					method: 'POST',
					body: JSON.stringify({
						email: info.email,
						username: info.username,
						password: info.password,
					}),
					headers: {
						auth: token,
						'Content-type': 'application/json',
					},
				})
					.then((res) => res.json())
					.then((result) => {
						if (result.error_code === 0) {
							setError('')
							toast.success('Register successful')
							onDone()
						} else {
							for (let attr in ERROR_CODE) {
								if (
									ERROR_CODE[attr] === parseInt(result.error_code) &&
									parseInt(result.error_code) !== 1
								) {
									setError(attr)
								}
							}
							onVerifyKey(result)
						}
					})
			} else {
				setError('Password does not match.')
			}
		} else {
			setError('Fill inputs.')
		}
	}
}

export const useStoreAccount = () => {
	const data = React.useContext(DataContext)
	React.useEffect(() => {
		const account = JSON.parse(localStorage.getItem('persist:account'))
		if (account) {
			data.setAccount(account)
		}
	}, [])
}

export const useLogout = () => {
	const data = React.useContext(DataContext)
	return () => {
		data.setAccount(null)
		localStorage.removeItem('persist:account')
	}
}

export const useLogin = (setError, onDone) => {
	const data = React.useContext(DataContext)
	return (info) => {
		if (info.username && info.password) {
			fetch(`${process.env.REACT_APP_API}/api/v1/login/login_user`, {
				method: 'POST',
				body: JSON.stringify(info),
				headers: {
					'Content-type': 'application/json',
				},
			})
				.then((res) => res.json())
				.then((result) => {
					if (result.error_code === 0) {
						setError('')
						data.setAccount(result.data)
						localStorage.setItem('persist:account', JSON.stringify(result.data))
						toast.success('Login successful')
						onDone()
					} else {
						for (let attr in ERROR_CODE) {
							if (ERROR_CODE[attr] === parseInt(result.error_code)) {
								setError(attr)
							}
						}
					}
				})
				.catch((err) => setError(err.message))
		} else {
			setError('Fill inputs.')
		}
	}
}
