import { REQUEST_LOADING, REQUEST_STATUS, REQUEST_UNLOAD } from 'constants/actions'

const initialState = {
	loading: false,
	txHash: '',
	message: '',
	status: '',
}
export const loadingReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_LOADING:
			return {
				...state,
				loading: true,
				txHash: action.payload.txHash ? action.payload.txHash : state.txHash,
				message: action.payload.message ? action.payload.message : state.message,
				status: action.payload.status,
			}
		case REQUEST_UNLOAD:
			return {
				...state,
				loading: false,
				txHash: '',
				message: '',
				status: '',
			}
		case REQUEST_STATUS:
			return {
				...state,
				txHash: action.payload.txHash ? action.payload.txHash : state.txHash,
				message: action.payload.message ? action.payload.message : state.message,
				status: action.payload.status,
			}
		default:
			return state
	}
}
