import TelegramIcon from 'assets/home/telegram__icon.png'
import DiscordIcon from 'assets/home/discord__icon.png'
import TwitterIcon from 'assets/home/twitter__icon.png'
import './Social.css'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'

const socialInfo = [
	{
		title: 'Telegram',
		icon: TelegramIcon,
		href: 'https://t.me/TheWastedLands',
		clickClass: 'click_telegram',
	},
	{
		title: 'Discord',
		icon: DiscordIcon,
		href: 'https://discord.com/invite/thewastedlands',
		clickClass: 'click_discord',
	},
	{
		title: 'Twitter',
		icon: TwitterIcon,
		href: 'https://twitter.com/thewastedlands',
		clickClass: 'click_twiter',
	},
]

export const SocialFixed = () => {
	const { t, i18n } = useTranslation()
	return (
		<div className='social-fixed'>
			{socialInfo.map((social, key) => (
				<div key={key} onClick={() => window.open(social.href, '_blank')} className='social'>
					<img src={social.icon} alt='Icon' className={`${social.clickClass}`} />
					<span className={`${social.clickClass}`}>{t(social.title)}</span>
				</div>
			))}
			<div className='social relative show-560'>
				<LanguageIcon />
				<span className='ml-1'>{`${i18n.language === 'en' ? 'English' : t('Chinese')}`}</span>
				<div className='nav-hover '>
					<ul>
						<li
							className='nav-link'
							onClick={() => {
								i18n.changeLanguage('en')
							}}
						>
							{'English'}
						</li>
						<li
							className='nav-link mt-2'
							onClick={() => {
								i18n.changeLanguage('cn')
							}}
						>
							{t('Chinese')}
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
