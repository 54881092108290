import React from 'react'
import { DataContext } from 'contexts/DataContext'
import { useDisconnectWallet } from './useWalletProvider'
import { toast } from 'react-toastify'
import { useWallet } from 'use-wallet'

const handleVerify = (signature, publicAddress, callback) => {
	return fetch(`${process.env.REACT_APP_API}/api/v1/login/verify/${publicAddress}`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify({
			signature,
		}),
	})
		.then((response) => response.json())
		.then((res) => {
			if (res.error_code === 0) {
				let user_key = JSON.parse(localStorage.getItem('user_key') || '[]')
				const index = user_key.findIndex((element) => element.address === res.data.address)
				if (index === -1) {
					user_key.push(res.data)
				} else {
					user_key[index] = res.data
				}
				localStorage.setItem('user_key', JSON.stringify(user_key))
				callback(res?.data)
				toast.success('Verify successful')
			} else {
				toast.error('Verify failed')
			}
		})
}

const requestSignature = async (publicAddress, challenge, callback, connector) => {
	const msgParams = challenge

	try {
		const result = await connector?.send('personal_sign', [msgParams, publicAddress])
		handleVerify(result.result, publicAddress, callback)
	} catch (err) {
		toast.error(err.message)
	}
}

export const useHandleSignIn = () => {
	const data = React.useContext(DataContext)
	const wallet = useWallet()
	return () => {
		const publicAddress = wallet.account
		fetch(`${process.env.REACT_APP_API}/api/v1/login/challenge/${publicAddress}`)
			.then((response) => response.json())
			// If yes, retrieve it. If no, create it.
			.then((res) => requestSignature(publicAddress, res.data.msg, data?.setUser, wallet.ethereum))
	}
}
export const useHandleLogout = () => {
	const data = React.useContext(DataContext)
	const onDisconnect = useDisconnectWallet()
	return async () => {
		localStorage.removeItem('user_key')
		data?.setUser(null)
		await onDisconnect()
	}
}
