/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { DataContext } from 'contexts/DataContext'
import { useCheckAccountActive } from './useWalletProvider'
import { useHandleSignIn } from './authenticate'

export const useAuthenticate = () => {
	const data = React.useContext(DataContext)
	const account = useCheckAccountActive()
	const sigin = useHandleSignIn()
	React.useEffect(() => {
		if (account) {
			const user_key = JSON.parse(localStorage.getItem('user_key') || '[]')
			const user = user_key.find(
				(userKey) => userKey.address.toLowerCase() === account?.toLowerCase()
			)
			if (user) {
				data?.setUser(user)
			} else {
				sigin()
			}
		}
	}, [account])
}
