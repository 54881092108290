import { toast } from 'react-toastify'
import React from 'react'
import { useQuery } from 'react-query'
import { ERROR_CODE } from 'constants/error_code'
import { useCheckAccountActive } from 'services/useWalletProvider'
import { DataContext } from 'contexts/DataContext'
export const useVerrifyAccount = () => {
	const data = React.useContext(DataContext)
	return (result) => {
		if (parseInt(result.error_code) === parseInt(1)) {
			localStorage.removeItem('user_key')
			data?.setUser(null)
			toast.error('Authenticate Error, Try verify again.')
		}
	}
}

export const useGetAccountInfo = () => {
	const account = useCheckAccountActive()
	return useQuery(
		['useGetPlayerHero'],
		() => {
			return fetch(process.env.REACT_APP_API + `/api/v1/player/get?address=${account}`)
				.then((res) => res.json())
				.then((result) => {
					return result.data
				})
		},
		{
			enabled: !!account,
			refetchInterval: 1000,
		}
	)
}

export const useUpdateAccount = (setError) => {
	const onVerifyKey = useVerrifyAccount()
	return async (info, token, onDone, onFailed) => {
		fetch(`${process.env.REACT_APP_API}/api/v1/player/update_username`, {
			method: 'POST',
			body: JSON.stringify(info),
			headers: {
				auth: token,
				'Content-type': 'application/json',
			},
		})
			.then((res) => res.json())
			.then((result) => {
				if (result.error_code === 0) {
					setError('')
					toast.success('Change successfully')
					onDone()
				} else {
					for (let attr in ERROR_CODE) {
						if (
							ERROR_CODE[attr] === parseInt(result.error_code) &&
							parseInt(result.error_code) !== 1
						) {
							setError(attr)
							onFailed()
						}
					}
					onVerifyKey(result)
				}
			})
	}
}

export const useUpdateAvatar = () => {
	const onVerifyKey = useVerrifyAccount()
	return (avatar, token, onDone) => {
		fetch(`${process.env.REACT_APP_API}/api/upload/avatar`, {
			method: 'POST',
			body: avatar,
			headers: {
				auth: token,
				// 'Content-type': 'multipart/form-data',
			},
		})
			.then((res) => res.json())
			.then((result) => {
				if (result.error_code === 0) {
					toast.success('Upload successfully')
					onDone(result?.data?.url)
				} else {
					for (let attr in ERROR_CODE) {
						if (
							ERROR_CODE[attr] === parseInt(result.error_code) &&
							parseInt(result.error_code) !== 1
						) {
							toast.error(attr)
						}
					}
					onVerifyKey(result)
				}
			})
	}
}
export const useUpdateAccountInfo = () => {
	const onVerifyKey = useVerrifyAccount()
	return (info, token, onDone) => {
		fetch(`${process.env.REACT_APP_API}/api/v1/player/update`, {
			method: 'POST',
			body: JSON.stringify(info),
			headers: {
				auth: token,
				'Content-type': 'application/json',
			},
		})
			.then((res) => res.json())
			.then((resultCh) => {
				if (resultCh.error_code === 0) {
					toast.success('Edit avatar successfully')
					onDone()
				} else {
					for (let attr in ERROR_CODE) {
						if (
							ERROR_CODE[attr] === parseInt(resultCh.error_code) &&
							parseInt(resultCh.error_code) !== 1
						) {
							toast.error(attr)
						}
					}
					onVerifyKey(resultCh)
				}
			})
	}
}
// export const useCheckUsername = () => {
// 	return (username) => {
// 		fetch(process.env.REACT_APP_API + `/api/v1/player/check_username?username=${username}`)
// 			.then((res) => res.json())
// 			.then((result) => {
// 				return result.data
// 			})
// 	}
// }
