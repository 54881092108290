import React from 'react'
import { onMoveAnimation, onTriggerMenu } from 'services/useDevelopUI'
import { useCheckAccountActive } from 'services/useWalletProvider'
import { useGetBalance } from 'services/useWalletProvider'
import MenuIcon from '@material-ui/icons/Menu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Link } from 'react-router-dom'
import Logo from 'assets/logo.png'
import './Navbar.css'
import LogoBNB from '../../assets/nav-asset/bnb.svg'
import { DataContext } from 'contexts/DataContext'
import { useGetAccountInfo } from 'queries/useMyAccount.query'
import { useHandleSignIn, useHandleLogout } from 'services/authenticate'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'

export const Navbar = () => {
	const { t, ready, i18n } = useTranslation()
	const [isConnectModal, setIsConnectModal] = React.useState(false)
	const onLogout = useHandleLogout()
	const account = useCheckAccountActive()
	const { data: balance } = useGetBalance()
	const data = React.useContext(DataContext)
	const { data: accountInfo } = useGetAccountInfo()
	const onSignin = useHandleSignIn()
	React.useEffect(() => {
		setTimeout(() => {
			if (account && isConnectModal) {
				if (!data?.user) {
					onSignin()
				} else if (!accountInfo?.player?.username && accountInfo) {
					onMoveAnimation('authenticate-form', 'moveInOpacity')
				}
				if (!!data?.user) {
					setIsConnectModal(false)
				}
			}
		}, 1000) // eslint-disable-next-line
	}, [isConnectModal, accountInfo, data?.user])
	React.useEffect(() => {
		if (
			// !(!accountInfo?.player?.username || !data?.user) ||
			accountInfo?.game_data?.beta_status !== 1
		)
			onMoveAnimation('header-text', 'moveInOpacity')
		// eslint-disable-next-line
	}, [accountInfo, data?.user])
	if (ready)
		return (
			<div className='navbar'>
				<div className='max-w-screen-xl container-md'>
					<div className='container-left'>
						<Link to='/'>
							<img src={Logo} alt='logo' />
						</Link>
						<ul className='list-nav'>
							<li className='nav-link hidden-mobile'>
								<Link to='/buy-wal'>{t('Buy $WAL')}</Link>
							</li>
							{/* <li className='nav-link'>
							<Link to='/marketplace'>Marketplace</Link>
						</li> */}
							<li className='nav-link cursor-pointer'>
								<span>
									{t('Marketplace')} <KeyboardArrowDownIcon />
								</span>
								<div className='nav-hover'>
									<ul>
										<Link to='/marketplace'>
											<li>{t('Warriors marketplace')}</li>
										</Link>
										<Link to='/frame-market'>
											<li>{t('Equipment marketplace')}</li>
										</Link>
									</ul>
								</div>
							</li>
							<li className='nav-link cursor-pointer'>
								<span>
									{t('Vesting')} & {t('Reward')} <KeyboardArrowDownIcon />
								</span>
								<div className='nav-hover'>
									<ul>
										<Link to='/vesting'>
											<li>{t('Vesting')}</li>
										</Link>
										<Link to='/reward'>
											<li>{t('Reward')}</li>
										</Link>
									</ul>
								</div>
							</li>
							<li className='nav-link  hidden-mobile'>
								<Link to='/white-paper'>{t('White Paper')}</Link>
							</li>
							<li className='nav-link cursor-pointer'>
								<span>
									{t('More')} <KeyboardArrowDownIcon />
								</span>
								<div className='nav-hover'>
									<ul>
										<li className='nav-link hidden show-mobile'>
											<Link to='/buy-wal'>{t('Buy $WAL')}</Link>
										</li>
										<li className='nav-link  hidden show-mobile'>
											<Link to='/white-paper'>{t('White Paper')}</Link>
										</li>
										<li onClick={() => window.open('https://linktr.ee/thewastedlands', '_blank')}>
											{t('Linktree')}
										</li>
										<Link to='/summon-warriors'>
											<li>{t('Summon Warriors')}</li>
										</Link>
										<li className='nav-link'>
											<Link to='/about-us'>{t('About Us')}</Link>
										</li>
										<li className='nav-link'>
											<Link to='/staking-warriors'>{t('Staking Warriors')}</Link>
										</li>
										<li className='nav-link'>
											<Link to='/gallery'>{t('Gallery')}</Link>
										</li>
										{/* <li className='nav-link'>
											<Link to='/vesting'>{t('Vesting')}</Link>
										</li> */}
									</ul>
								</div>
							</li>
						</ul>
					</div>
					<div className='flex flex-row items-center justify-center'>
						{!account ? (
							<div
								onClick={() => onMoveAnimation('connect-modal', 'moveInOpacity')}
								className='cursor-pointer btn-primary'
							>
								{t('Connect Wallet')}
							</div>
						) : (
							<div className='relative profile-container'>
								<div
									onClick={() => onMoveAnimation('account-info', 'moveInOpacity')}
									style={{ backgroundColor: '#2A2A2D' }}
									className='relative flex flex-row items-center justify-center py-2 pl-6 pr-10 rounded-full cursor-pointer profile'
								>
									<div className='flex flex-row items-center justify-center mr-4 bnb-info'>
										<span className='mr-2'>{parseFloat(balance).toFixed(2)}</span>
										<img className='bnb-logo' src={LogoBNB} alt='Binance' />
									</div>
									<span className='account-address'>
										{account?.length !== 0 ? account : t('No account selected')}
									</span>
								</div>
								<div className='absolute account-hover__container'>
									<span className='flex items-center btn-dropdown'>
										<KeyboardArrowDownIcon />
									</span>
									<div className='account-hover'>
										<ul className='flex flex-col items-center'>
											<li
												onClick={() => {
													if (data.user) {
														onMoveAnimation('account-info', 'moveOutOpacity')
														onMoveAnimation('qr-code', 'moveInOpacity')
													} else {
														onSignin()
													}
												}}
											>
												{t('Token session')}
											</li>
											<Link to='/inventory'>
												<li>{t('Account detail')}</li>
											</Link>

											{/* <Link to='/vesting'>
											<li>Vesting</li>
										</Link> */}
											<div
												onClick={() => {
													onMoveAnimation('account-info', 'moveOutOpacity')
													onLogout()
												}}
												className='mb-2 cursor-pointer'
											>
												{t('Log out')}
											</div>
										</ul>
									</div>
								</div>
							</div>
						)}
						<MenuIcon onClick={() => onTriggerMenu()} className='icon-menu-bar' />
						{account ? (
							!data.user ? (
								<div
									onClick={() => onSignin()}
									className='cursor-pointer btn-secondary ml-4 hidden-btn-mobile'
								>
									{t('Sign')}
								</div>
							) : !accountInfo?.player?.username ? (
								<div
									onClick={() => onMoveAnimation('authenticate-form', 'moveInOpacity')}
									className='cursor-pointer btn-secondary ml-4  hidden-btn-mobile'
								>
									{t('Register')}
								</div>
							) : null
						) : (
							<div
								onClick={() => {
									onMoveAnimation('connect-modal', 'moveInOpacity')
									setIsConnectModal(true)
								}}
								className='cursor-pointer btn-secondary ml-4  hidden-btn-mobile'
							>
								{t('Register')}
							</div>
						)}
						<div className='list-nav show-mobile hidden-560'>
							<li className='nav-link cursor-pointer'>
								<div className=''>
									<LanguageIcon />
								</div>
								<div className='nav-hover'>
									<ul>
										<li
											className='nav-link'
											onClick={() => {
												i18n.changeLanguage('en')
											}}
										>
											{'English'}
										</li>
										<li
											className='nav-link'
											onClick={() => {
												i18n.changeLanguage('cn')
											}}
										>
											{t('Chinese')}
										</li>
									</ul>
								</div>
							</li>
						</div>
					</div>
				</div>
			</div>
		)
}
