import { getNodeUrl } from 'services/utils/getRpcUrl'
import Web3 from 'web3'

export const getWeb3 = (_isETH = false) => {
	const RPC_URL = getNodeUrl(_isETH)
	const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 })
	const web3 = new Web3(httpProvider)
	return web3
}

export const getContract = (abi, address, _isETH = false, contractOptions) => {
	const web3 = getWeb3(_isETH)
	return new web3.eth.Contract(abi, address, contractOptions)
}
