import React from 'react'
import { Link } from 'react-router-dom'
import { onTriggerMenu } from 'services/useDevelopUI'
import CloseIcon from '@mui/icons-material/Close'
import './MenuList.css'
import { useTranslation } from 'react-i18next'

export const MenuList = () => {
	const { t, ready } = useTranslation()
	if (ready)
		return (
			<div onClick={() => onTriggerMenu()} id={'menu'} className='menu-list'>
				<CloseIcon style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 100 }} />
				<div className='list'>
					<Link to='/buy-wal'>
						<div className='item'>{t('Buy WAL')}</div>
					</Link>
					<Link to='/summon-warriors'>
						<div className='item'>{t('Summon Warriors')}</div>
					</Link>
					<Link to='/gallery'>
						<div className='item'>{t('Gallery')}</div>
					</Link>
					<Link to='/marketplace'>
						{' '}
						<div className='item'>{t('Marketplace')}</div>
					</Link>
					{/* <Link to='/frame-market'>
					{' '}
					<div className='item'>Equipment marketplace</div>
				</Link> */}
					<Link to='/inventory'>
						<div className='item'>{t('Account Detail')}</div>
					</Link>
					<Link to='/vesting'>
						{' '}
						<div className='item'>{t('Vesting')}</div>
					</Link>
					<Link to='/reward'>
						{' '}
						<div className='item'>{t('Reward')}</div>
					</Link>
					<Link to='/white-paper'>
						<div className='item'>{t('White Paper')}</div>
					</Link>
					<a
						href='https://the-wasted-lands.gitbook.io/the-wasted-lands/'
						target='_blank'
						rel='noreferrer'
					>
						<div className='item'>{t('Gitbook')}</div>
					</a>
					<Link to='/staking-warriors'>
						<div className='item'>{t('Staking Warriors')}</div>
					</Link>

					<Link to='/about-us'>
						<div className='item'>{t('About Us')}</div>
					</Link>
				</div>
			</div>
		)
}
