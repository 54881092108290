import React from 'react'
import { Link } from 'react-router-dom'
import './AvatarListView.css'
import { Icon } from 'assets/inventory'
import { useTranslation } from 'react-i18next'
/**
 * @author
 * @function AvatarListView
 **/

export const AvatarListView = (props) => {
	const { t, ready } = useTranslation()
	const { playerAvatarData } = props
	// console.log('frame', playerFrameData)
	if (ready)
		return (
			<>
				{playerAvatarData?.avatars?.length > 0 ? (
					<div className='grid avatar-container mb-14 w-full max-width-848'>
						{
							//eslint-disable-next-line
						playerAvatarData?.avatars?.map((item, key) => {
								return (
									<Link to={`/avatars/${item?.id}`} key={key}>
										<div
											className='avatar-item-container relative flex items-center justify-center'
											// 	war?.rarity === 4
											// 		? 'mystic_bg'
											// 		: war?.rarity === 3
											// 		? 'epic_bg'
											// 		: war?.rarity === 2
											// 		? 'rare_bg'
											// 		: 'normal_bg'
											// }`}
										>
											<div className='avatar__icon-id'>#{item?.id}</div>
											{/* <div className='frame__amount'>
											<span className='opacity-50'>{`Amount: `}</span>
											{item?.amount}
										</div> */}
											<div className='avatar-img-container'>
												<img className='avatar-img' src={item?.image} alt='' />
											</div>
											{item.price ? (
												<div className='price-container'>
													<div className='flex items-center justify-between'>
														<div className='flex items-center'>
															<img className='mr-1 war-icon' src={Icon} alt={Icon} />
															<span className='war-bnb'>{`${item?.price} BNB`}</span>
														</div>
														<span className='war-price'>{`$${Math.round(
															item?.price * playerAvatarData?.bnb_price
														)}`}</span>
													</div>
												</div>
											) : null}
										</div>
									</Link>
								)
							})
						}
					</div>
				) : (
					<div className='w-full mt-6 max-width-848'>
						<div className='inventory-empty-hero'>
							{t(`You have no avatars, buy one now!`)}
							<br />({t(
								`If you just bought avatars, please be patient, the chain is minting`
							)}){' '}
						</div>

						<div
							className='mt-6 mb-32 btn-primary '
							onClick={() => {
								window?.open('https://metaverse.thewastedlands.io/nft-mint', 'blank')
							}}
						>
							{t(`Buy avatars`)}
						</div>
					</div>
				)}
			</>
		)
}
